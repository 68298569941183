<template>
  <v-card class="ma-5" :elevation="0" color="grey-lighten-4" flat outlined>
    <v-toolbar color="primary" :dark="true">
      <v-toolbar-title>My Presidents</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card-text class="pa-3">
      <v-list lines="three">
        <v-list-item v-for="item in presidents" :key="item.id">
          <div class="row mb-4">
            <div class="col-3">
              <image-field
                size="mini"
                :disabled="true"
                :show-zoom="false"
                :image-click="true"
                :grid="true"
                :width="0"
                :height="50"
                :value="item.photo"
              />
            </div>
            <div class="col-9">
              <div class="row">
                <div class="col-12 pb-0 title">
                  {{ item.full_name }}
                </div>
                <div
                  class="col-12 pt-0 pb-0"
                  style="max-height: 35px; overflow: hidden"
                >
                  {{ item.name }}
                </div>
                <div
                  class="col-12 pt-0"
                  style="max-height: 35px; overflow: hidden"
                >
                  {{ item.start_date }} - {{ item.end_date }}
                </div>
              </div>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import ImageField from "@/components/custom/ImageField.vue";
export default {
  created() {
    this.getPresidents();
  },
  components: {
    ImageField,
  },
  data() {
    return {
      valid: true,
      loading: false,
      presidents: [],
    };
  },
  methods: {
    getPresidents() {
      this.loading = true;
      this.api("public/constituency-presidents")
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.presidents = response.data;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  name: "ConstituencyPresidents",
};
</script>
